<template>
	<div class="full-height">

		<div class="full-height flex-column">
			<div class="pa-10 box text-right">

				<date_picker
					:date="date"
					@click="setDate"
					class="mr-10"
				></date_picker>

				<select
					v-if="user.role != codes.type_code_supply"
					v-model="search.admin_type"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">구분</option>
					<template
						v-for="code in codes.A001.items"
					>
						<option
							v-if="code.sub_code < 4"
							:key="'admin_type_' + code.total_code"
							:value="code.total_code"
						>{{ code.code_name }}</option>
					</template>
					<option value="system_fee">운영 수수료</option>
				</select>

				<select
					v-model="search.virtual_uid"
					class="mr-10 input-box-inline size-px-12"
					@change="getSearch(1)"
				>
					<option value="">지갑 구분</option>
					<option
						v-for="(virtual, v_index) in items_virtual"
						:key="'virtual_' + v_index"
						:value="virtual.uid"
					>{{ virtual.virtual_name }}</option>
				</select>

				<select
					v-model="search.is_settlement"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">정산여부</option>
					<template
						v-for="code in codes.S001.items"
					>
						<option
							v-if="code.sub_code > 0"
							:key="'admin_type_' + code.total_code"
							:value="code.code_value"
						>{{ code.code_name }}</option>
					</template>
				</select>

				<select
					v-model="search.settlement_type"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">지급방식</option>
					<option value="wallet">지갑입금</option>
					<option value="bank">은행출금</option>
				</select>

				<select
					v-model="search.is_deposit"
					class="pa-5 box mr-10"
					@change="getSearch(1)"
				>
					<option value="">지급여부</option>
					<template
						v-for="code in codes.S002.items"
					>
						<option
							v-if="code.sub_code > 0"
							:key="'admin_type_' + code.total_code"
							:value="code.code_value"
						>{{ code.code_name }}</option>
					</template>
				</select>

				<select
					v-model="search.search_type"
					class="pa-5 box mr-10"
				>
					<option value="">검색 조건</option>

					<option
						value="admin.admin_id"
					>아이디</option>
					<option
						value="shop_name"
					>서비스 명</option>

				</select>

				<input
					v-model="search.search_value"
					class="pa-5-10 box vertical-middle mr-10 "
					placeholder="검색어를 입력하세요"
					@keyup.enter="getSearch"
				/>

				<button
					class="pa-5-10 btn-blue mr-10 vertical-middle"
					@click="getSearch"
				>검색</button>

				<button
					class="pa-5-10 btn-green vertical-middle"
					@click="getExcel"
					:disabled="items.length <= 0"
				>엑셀 다운로드</button>
			</div>

			<div class="mt-10 pa-10 bg-white full-height">
				<div
					v-if="items.length > 0"
				>
					<table
						class="table table-even mt-10"
					>
						<colgroup>
							<col width="60px" />
							<col width="80px" />

						</colgroup>
						<thead>
						<tr>
							<th>
								<button
									@click="setAll"
								>
									<v-icon
										v-if="is_all"
										class="color-primary">mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
								</button>
							</th>
							<th>No</th>
							<th>정산일</th>

							<th>구분</th>
							<th>아이디</th>
							<th>서비스 명</th>

							<th>지갑 구분</th>

							<th>입금금액</th>
							<th>입금 수수료</th>
							<th>정산 예정 금액</th>
							<th>차감금액</th>
							<th>정산금액</th>

							<th>정산여부</th>
							<th>지급방식</th>
							<th>지급여부</th>
							<th>관리</th>
						</tr>
						</thead>
						<tbody>
						<tr
							v-for="(item, index) in item_list"
							:key="'settlement_' + item.uid"
						>
							<td>
								<button
									v-if="item.is_deposit == 0 || item.is_deposit == 2"
									@click="setAllItem(item)"
								>
									<v-icon
										v-if="item.is_all"
										class="color-primary">mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
								</button>
							</td>
							<td>{{ items.length - index}}</td>
							<td>{{ item.year }}.{{ item.month }}.{{ item.day }}</td>

							<td>{{ item.admin_type_name}}</td>
							<td>{{ item.account_id }}</td>
							<td>{{ item.shop_name }}</td>
							<td>{{ item.virtual_name }}</td>

							<td class="text-right">{{ item.sale_amount | makeComma }} 원</td>
							<td class="text-right">{{ item.income_amount | makeComma }} 원</td>
							<td class="text-right">{{ item.hold_amount | makeComma }} 원</td>

							<td class="text-right">
								<input v-if="auth.is_admin && (item.is_deposit == 0 || item.is_deposit == 2)  && (item.agency_type == 'A001002' || item.agency_type == 'A001003')" v-model="item.minus_amount" class="input-box" :rules="$rules.max(item, 'minus_amount', 9)" type="number"/> <span v-else>{{ item.minus_amount | makeComma}} 원</span>
							</td>
							<td class="text-right font-weight-bold">{{ item.amount | makeComma }} 원</td>
							<td :class="item.is_settlement_color">{{ item.is_settlement_name }}</td>
							<td>{{ item.settlement_type_name }}</td>

							<td :class="item.is_deposit_color">{{ item.is_deposit_name }}</td>
							<td>
								<button
									class="btn-blue pa-5-10 mr-10"
									@click="toDetail(item)"
								>상세 정보</button>
								<button
									v-if="item_detail.is_settlement == '0'"
									v-show="false"
									class="btn-success pa-5-10"
									@click="doSettlementConfirm(item)"
								>정산 확인 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
								<button
									v-if="item_detail.is_settlement == '1' && item_detail.is_deposit == '0'"
									v-show="false"
									class="btn-success pa-5-10"
									@click="doDepositConfirm(item)"
								>지급 완료 <v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
							</td>
						</tr>
						</tbody>
					</table>
					<div
						v-if="user.account_type == 'A001001'"
						class="mt-10 pa-10 text-right"
					>

						<input :value="date" class="input-box-inline text-center bg-gray-light mr-10 " readonly/>

						<select
							v-model="search.virtual_uid"
							class="mr-10 input-box-inline size-px-12"
							disabled
						>
							<option value="">지갑 구분</option>
							<option
								v-for="(virtual, v_index) in items_virtual"
								:key="'virtual_' + v_index"
								:value="virtual.uid"
							>{{ virtual.virtual_name }}</option>
						</select>

						<button
							class="btn-inline btn-primary mr-10"

							@click="onSettlement"
						>선택 정산 등록</button>

						<button
							class="btn-inline btn-primary"
							@click="onDeposit"
						>정산 지급</button>
					</div>
				</div>
				<Empty
					v-else
				></Empty>

				<Pagination
					v-if="false"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			height="450px"

			@close="close"
		>
			<SettlementDetail
				slot="modal-content"
				v-if="item_detail.uid"
				:Axios="Axios"
				:user="user"
				:codes="codes"
				:TOKEN="TOKEN"
				:item="item_detail"
				:year="search.year"
				:month="search.month"
				:day="search.day"

				@click="close"
				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
				@setNotify="setNotify"
				@success="success"

				class="full-width"
			></SettlementDetail>
		</Modal>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
		></Excel>
	</div>
</template>

<script>
import SettlementDetail from "@/view/Settlement/SettlementDetail";
import Modal from "@/components/Modal";
import Empty from "@/view/Layout/Empty";
import date_picker from "@/components/DatePicker"
import Pagination from "@/components/Pagination";
import Excel from "../../components/Excel";

export default {
	name: 'SettlementList'
	,
	components: {Empty, Modal, SettlementDetail, date_picker, Pagination, Excel},
	props: ['Axios', 'TOKEN', 'codes', 'user', 'auth']
	,data: function(){
		return {
			program: {
				name: '입금 정산 실행'
				,top: true
				,title: true
				,bottom: false
			}
			,is_excel: false
			,excel_data: {
				name: '입금 정산 실행'
				,header: [
					{ key: 0, name: '정산일', column: 'date'}
					,{ key: 0, name: '아이디', column: 'account_id'}
					,{ key: 0, name: '상점명', column: 'shop_name'}
					,{ key: 0, name: '표기구분', column: 'shop_summary'}
					,{ key: 0, name: '표기번호', column: 'shop_sort'}
					,{ key: 0, name: '지갑 구분', column: 'virtual_name'}
					,{ key: 0, name: '판매건수', column: 'total_count'}
					,{ key: 0, name: '결제금액', column: 'income_amount'}
					,{ key: 0, name: '차감금액', column: 'minus_amount'}
					,{ key: 0, name: '정산금액', column: 'amount'}
					,{ key: 0, name: '정산여부', column: 'is_settlement_name'}
					,{ key: 0, name: '지급여부', column: 'is_deposit_name'}
				]
				,content: null
			}
			,items: []
			,year_start: 2021
			,item_do: {
				ATOKEN: this.TOKEN
				,year: new Date().getFullYear()
				,month:  new Date().getMonth() + 1
			}
			,search: this.$storage.init({
				year: new Date().getFullYear()
				, month:  new Date().getMonth() + 1
				, day: new Date().getDate()
				, admin_type: ''
				, is_settlement: ''
				, is_deposit: ''
				, payment_type: ''
				, search_type: ''
				, search_value: ''
				, page: 1
				, virtual_uid: ''
				, shop_summary: ''
				, settlement_type: ''
			})
			,item_detail: {

			}
			,item_settlement: null
			,item_deposit: null
			,is_modal: false
			,modal_option: {
				title: '입금 정산 실행  > 상세 정보'
				,top: true
				,bottom: false
			}
			,reason: ''
			, items_virtual: []
			, is_all: true
		}
	}
	,computed: {
		item_list: function(){

			return this.items.filter((item) => {

				for(let i = 0; i < this.codes.S001.items.length; i ++){
					if(item.is_settlement == this.codes.S001.items[i].code_value){
						item.is_settlement_name = this.codes.S001.items[i].code_name
					}
				}
				for(let i = 0; i < this.codes.S002.items.length; i ++){
					if(item.is_deposit == this.codes.S002.items[i].code_value){
						item.is_deposit_name = this.codes.S002.items[i].code_value == '0' ? '-' : this.codes.S002.items[i].code_name
					}
				}

				switch(item.agency_type){
					case 'A001001':
						item.admin_type_name = '본사'
						item.is_admin = true
						item.hold_amount = item.income_amount
						break;
					case 'A001002':
						item.admin_type_name = '총판'
						item.is_distributor = true
						item.hold_amount = item.income_amount
						break;
					case 'A001003':
						item.admin_type_name = '대리점'
						item.is_agency = true
						item.hold_amount = item.income_amount - item.fee
						item.income_amount = item.sale_amount - item.income_amount
						break;
					default:
						item.admin_type_name = '운영 수수료'
						item.shop_name = '운영 수수료'
						item.hold_amount = item.income_amount
						break;
				}

				switch (item.settlement_type){
					case 'bank':
						item.settlement_type_name = '은행 출금'
						break
					case 'wallet':
						item.settlement_type_name = '지갑 입금'
						break
				}

				return item
			})
		}
		,year_list: function(){
			let start = 2021
			let date = new Date()
			let year = date.getFullYear()
			let years = []
			for(let i = start; i <= year; i++){
				years.push(i)
			}

			return years
		}
		, date: function(){
			let t = ''
			t = this.search.year + '-' + ('00' + this.search.month).slice(-2) + '-' + ('00' + this.search.day).slice(-2)
			return t
		}

		, depost_virtual_name: function(){
			let t = ''
			this.items_virtual.filter( (item) => {
				if(this.search.virtual_uid == item.uid ){
					t = item.virtual_name
				}
			})
			return t
		}
		, total_amount: function(){
			let t = {
				total: 0
				, a1: 0
				, a2: 0
				, a3: 0
				, a4: 0
				, c1: 0
				, c2: 0
				, c3: 0
				, c4: 0
			}
			this.items.filter( (item) => {
				t.total += Number(item.income_amount)
				switch (item.shop_summary){
					case '지사':
						t.a1 += Number(item.income_amount)
						t.c1++
						break;
					case '총판':
						t.a2 += Number(item.income_amount)
						t.c2++
						break;
					case '대리점':
						t.a3 += Number(item.income_amount)
						t.c3++
						break;
					case '가맹점':
						t.a4 += Number(item.income_amount)
						t.c4++
						break;
				}
			})
			return t
		}

		, items_list: function(){
			let t = []
			this.items.filter( (item) => {
				if(item.is_all){
					t.push(item)
				}
			})

			return t.length > 0 ? JSON.stringify(t) : t
		}

	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getSettlementList'
					,data: this.search
				})

				if(result.success){
					this.items = result.data
					this.$storage.setQuery(this.search)
					this.items.filter( (item) => {
						this.$set(item, 'is_all', true)
					})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSettlement: async function(){

			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'settlement/putSettlement'
					,data: {
						items: this.items_list
					}
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postDeposit: async function(){

			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'settlement/postDeposit'
					,data: {
						virtual_uid: this.search.virtual_uid
						, items: this.items_list
						, year: this.search.year
						, month: this.search.month
						, day: this.search.day

					}
				})

				if(result.success){
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,success: function(){
			this.close()
			this.getData()
		}
		,close: function(){
			this.item_detail = {}
			this.item_settlement = null
			this.item_deposit = null
			this.is_modal = false
		}
		,toDetail: function(item){
			this.is_modal = true
			this.item_detail = item
			this.modal_option.title = '정산 상세 내역 - ' + item.shop_name
		}
		,doSettlementConfirm: function(item){
			this.item_settlement = item
		}
		,doDepositConfirm: function(item){
			this.item_deposit = item
		}
		,setNotify: function({ type, message }){
			this.$bus.$emit('notify', { type: type, message: message })
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
		, setDate: function(date){
			let t = date.split('-')
			console.log('setDate', date)
			this.search.year = t[0]
			this.search.month = t[1]
			this.search.day = t[2]

			this.getData()
		}
		,getExcel: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getSettlementList'
					,data: {
						ATOKEN: this.search.ATOKEN
						, year: this.search.year
						, month:  this.search.month
						, day: this.search.day
						, admin_type: this.search.admin_type
						, is_settlement: this.search.is_settlement
						, is_deposit: this.search.is_deposit
						, payment_type: this.search.payment_type
						, search_type: this.search.search_type
						, search_value: this.search.search_value
						, page: 1
						, list_cnt: 2000
					}
				})

				if(result.success){
					this.excel_data.content = result.data
					this.is_excel = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
					this.search.virtual_uid = this.items_virtual[0].uid
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSettlement: function(){
			if(this.search.virtual_uid){
				let t = this.date + '일 [' + this.depost_virtual_name +'] 선택된 항목을 정산 처리하시겠습니까?'
				if(this.search.shop_summary){
					t = this.date + '일 [' + this.depost_virtual_name +'] [' + this.search.shop_summary + '] 선택된 항목을 정산 처리하시겠습니까?'
				}
				if(confirm(t)){
					this.postSettlement();
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, onDeposit: function(){
			if(this.search.virtual_uid){
				let t = this.date + '일 [' + this.depost_virtual_name +'] 선택된 항목의 정산 금액을 지급하시겠습니까?'
				if(this.search.shop_summary){
					t = this.date + '일 [' + this.depost_virtual_name +'] [' + this.search.shop_summary + '] 선택된 항목의 정산 금액을 지급하시겠습니까?'
				}
				if(confirm(t)){
					this.postDeposit();
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, setAll: function(){
			this.is_all = !this.is_all
			this.items.filter( (item) => {
				item.is_all = this.is_all
			})
		}
		, setAllItem: function(item){
			item.is_all = !item.is_all
			if(!item.is_all){
				this.is_all = false
			}
			let t = false
			this.items.filter((item) => {
				if(!item.is_all){
					t = true
				}
			})
			this.is_all = !t
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
		await this.getVirtualList()
	}
}
</script>
